import { shopCellarLocations } from './shopCellar/locations.js';
import { dialogue } from '../../dialogue.js';

const elarianLocations = [
  ...shopCellarLocations,
  {
    name: 'elarianGates',
    displayName: 'Elarian Gates',
    width: 5,
    height: 6,
    pathImage: 'grass',
    wallImage: 'stoneBrick',
    walls: ['top'],
    tiles: [{ type: 'entrance', x: 2, y: 5, tileImage: 'castleGate', tileBackground: 'wall', location: 'elarian', outputDirection: 'down' }],
  },
  {
    name: 'elarian',
    displayName: 'Elarian',
    width: 9,
    height: 9,
    pathImage: 'grass',
    wallImage: 'stoneBrick',
    walls: ['top', 'left', 'right', 'bottom'],
    tiles: [
      { type: 'entrance', x: 4, y: 0, tileImage: 'castleGate', tileBackground: 'wall', location: 'elarianGates', outputDirection: 'up' },
      { type: 'entrance', x: 2, y: 4, tileImage: 'shop', location: 'elarianShop', outputDirection: 'down' },
      { type: 'entrance', x: 6, y: 4, tileImage: 'tower', location: 'elarianTower', outputDirection: 'down' },
      { type: 'entrance', x: 2, y: 6, tileImage: 'cathedral', outputDirection: 'down', location: 'elarianCathedral' },
      { type: 'entrance', x: 6, y: 6, tileImage: 'castle', outputDirection: 'down', location: 'elarianCastle' },
      { type: 'entrance', x: 4, y: 8, tileImage: 'castleGate', tileBackground: 'wall', outputDirection: 'down', location: 'elarianCourtyard' },
    ],
  },
  {
    name: 'elarianShop',
    displayName: "Cedric's Shop",
    width: 5,
    height: 6,
    pathImage: 'woodFloor',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'entrance', x: 2, y: 0, tileImage: 'door', tileBackground: 'wall', location: 'elarian', outputDirection: 'up' },
      { type: 'object', x: 0, y: 3, tileImage: 'counter', 'z-index': 3 },
      {
        type: 'entrance',
        x: 4,
        y: 5,
        tileImage: 'cellarDoor',
        location: 'shopCellarEntrance',
        locked: true,
        lockedDialogue: 'elarianShop.lockedCellar',
        outputDirection: 'down',
      },
    ],
  },
  {
    name: 'elarianTower',
    displayName: "Eldwin's Tower",
    width: 5,
    height: 6,
    pathImage: 'woodFloor',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'entrance', x: 2, y: 0, tileImage: 'door', tileBackground: 'wall', location: 'elarian', outputDirection: 'up' },
      { type: 'object', x: 2, y: 3, tileImage: 'cauldron', 'z-index': 3 },
      { type: 'object', x: 0, y: 5, tileImage: 'bookshelf', noMovement: true },
      { type: 'object', x: 1, y: 5, tileImage: 'bookshelf', noMovement: true },
      { type: 'object', x: 2, y: 5, tileImage: 'bookshelf', noMovement: true },
      { type: 'object', x: 3, y: 5, tileImage: 'bookshelf', noMovement: true },
      { type: 'object', x: 4, y: 5, tileImage: 'bookshelf', noMovement: true },
    ],
  },
  {
    name: 'elarianCathedral',
    displayName: 'Elarian Cathedral',
    width: 5,
    height: 6,
    pathImage: 'woodFloor',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'entrance', x: 2, y: 0, tileImage: 'door', tileBackground: 'wall', location: 'elarian', outputDirection: 'up' },
      { type: 'object', x: 0, y: 5, tileImage: 'stainedGlass1', tileBackground: 'wall', noMovement: true },
      { type: 'object', x: 1, y: 5, tileImage: 'stainedGlass2', tileBackground: 'wall', noMovement: true },
      { type: 'object', x: 2, y: 5, tileImage: 'altar', noMovement: true },
      { type: 'object', x: 3, y: 5, tileImage: 'stainedGlass3', tileBackground: 'wall', noMovement: true },
      { type: 'object', x: 4, y: 5, tileImage: 'stainedGlass4', tileBackground: 'wall', noMovement: true },
    ],
  },
  {
    name: 'elarianCastle',
    displayName: 'Elarian Castle',
    width: 5,
    height: 6,
    pathImage: 'woodFloor',
    wallImage: 'stoneBrick',
    walls: ['bottom', 'top'],
    tiles: [
      { type: 'entrance', x: 2, y: 0, tileImage: 'door', tileBackground: 'wall', location: 'elarian', outputDirection: 'up' },
      { type: 'entrance', x: 2, y: 5, tileImage: 'castleGate', tileBackground: 'wall', outputDirection: 'down', location: 'elarianThroneRoom' },
    ],
  },
  {
    name: 'elarianThroneRoom',
    displayName: 'Elarian Throne Room',
    width: 5,
    height: 6,
    pathImage: 'woodFloor',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'wall', x: 0, y: 0 },
      { type: 'wall', x: 1, y: 0 },
      { type: 'entrance', x: 2, y: 0, tileImage: 'castleGate', tileBackground: 'wall', location: 'elarianCastle', outputDirection: 'up' },
      { type: 'wall', x: 3, y: 0 },
      { type: 'wall', x: 4, y: 0 },
      { type: 'object', x: 2, y: 4, tileImage: 'throne' },
    ],
  },
  {
    name: 'elarianCourtyard',
    displayName: 'Elarian Courtyard',
    width: 8,
    height: 6,
    pathImage: 'grass',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'object', x: 0, y: 1, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 0, y: 2, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 0, y: 4, tileImage: 'tree', noMovement: true },
      { type: 'object', x: 0, y: 5, tileImage: 'tree', noMovement: true },
      { type: 'entrance', x: 4, y: 0, tileImage: 'castleGate', tileBackground: 'wall', location: 'elarian', outputDirection: 'up' },
      {
        type: 'entrance',
        x: 0,
        y: 3,
        locked: true,
        lockedDialogue: 'courtyardGuard.lockedForest',
        location: 'goblinForestRight',
        outputDirection: 'right',
      },
      {
        type: 'object',
        x: 4,
        y: 3,
        tileImage: 'dragonStatue',
        noMovement: true,
        displayFunction: () => {
          if (!dialogue.checkDialogue('elarianCourtyard.firstSaveGame')) return 'Investigate the dragon statue';
          else return 'Save your game';
        },
        dialogueFunction: () => {
          if (!dialogue.checkDialogue('elarianCourtyard.firstSaveGame')) return 'elarianCourtyard.investigateDragonStatue';
          return 'elarianCourtyard.saveGame';
        },
      },
    ],
  },
];

export { elarianLocations };
