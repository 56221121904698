import { dialogue } from '../../dialogue.js';
import { location } from '../../location/index.js';
import { player } from '../../player.js';
import { util } from '../../util.js';

const dialogues = {
  castleGuard: {
    talk: {
      text: `"Halt! Who are you and what is your business in the castle?"`,
      options: [
        {
          display: 'Give your name and tell him why you are here',
          text: `"I am Thaddeus, son of Bartholomew. I am here to seek an audience with the king. I would like his blessing on my quest to defeat The Dark One."`,
          next: 'castleGuard.talk.seeTheKing',
        },
        {
          display: 'Use a fake identity and try to convince him to let you in',
          text: `"I am a merchant from the far east. I have come to deliver a special gift to the king."`,
          next: 'castleGuard.talk.convinceGuard',
        },
      ],
      seeTheKing: {
        text: `"Welcome to the castle, Thaddeus. I'm sorry, but I can't let you in. Only those with a personal invitation from the king may enter the throne room."`,
      },
      convinceGuard: {
        text: `"You say you have a gift for the king? What is it?"`,
        options: [
          {
            display: 'Tell him you have a rare jewel',
            text: `"I have a rare jewel that is said to bring good luck to those who possess it. I would like to present it to the king as a token of my respect."`,
            next: 'castleGuard.talk.convinceGuard.deliveryAttempt',
          },
          {
            display: 'Tell him you have a magical artifact',
            text: `"I have a magical artifact that is said to have been created by the gods themselves. I would like to present it to the king as a token of my respect."`,
            next: 'castleGuard.talk.convinceGuard.deliveryAttempt',
          },
        ],
        deliveryAttempt: {
          text: `"Wow! That is quite impressive. I will have to take a look at it before I can let you in. Please hand it over."`,
          options: [
            {
              display: 'Tell him that it needs to be delivered in person',
              text: `"I'm sorry, but only I can deliver this gift to the king. It is of the utmost importance that I present it to him myself."`,
              next: 'castleGuard.talk.convinceGuard.deliveryAttempt.refusal',
            },
            {
              display: 'Give him something from your pocket',
              text: `"Alright, here you go."`,
              next: 'castleGuard.talk.convinceGuard.deliveryAttempt.giveRock',
            },
          ],
          refusal: {
            text: `"I'm sorry, but I can't let you in. Those are the rules. If you can't hand it over, I can't help you."`,
          },
          giveRock: {
            text: `"What is this? A rock? Is this some kind of joke? I'm sorry, but I can't let you in. Those are the rules."`,
          },
        },
      },
    },
    noEntry: {
      text: `"I'm sorry, but only those with a personal invitation from the king may enter the throne room."`,
    },
    noEntry2: {
      text: `"I'm sorry, but I can't let you in. Those are the rules."`,
    },
    wineDelivery: {
      text: `"Ah, Cedric. It has been a while. How are you doing?"`,
      next: 'shopkeeperCedric.wineDelivery',
    },
    wineDelivery2: {
      text: `"Glad to hear it! Why don't you hand it over? I will make sure the king gets it and will bring your payment to you later."`,
      next: 'shopkeeperCedric.wineDelivery2',
    },
    wineDelivery3: {
      text: `"I'm sorry, but you know the law. Only those with a personal invitation from the king may enter the throne room."`,
      next: 'shopkeeperCedric.wineDelivery3',
    },
    wineDelivery4: {
      text: `"Hmmm... You said your friend took care of the rat boss? Do you think he would be able to help me with something?"`,
      next: 'shopkeeperCedric.wineDelivery4',
    },
    askForCrown: {
      text: `"The Goblin King stole the king's crown and I need someone to get it back. If he can do that, I will let you both in."`,
      options: [
        {
          display: 'Offer to help',
          text: `"Alright, I can do that for you. Where can I find the Goblin King?"`,
          next: 'castleGuard.askForCrown.goblinKingAccept',
        },
        {
          display: 'Ask for payment',
          text: `"I could do that for you, but I will need some payment for my services."`,
          next: 'castleGuard.askForCrown.goblinKingPayment',
        },
        {
          display: "Ask why he can't do it himself",
          text: `"Why can't you do it yourself? Isn't that your job?"`,
          next: 'castleGuard.askForCrown.goblinKingWhy',
        },
      ],
      goblinKingAccept: {
        text: `"The Goblin King is in the forest to the west of the castle's courtyard in the north. Good luck!"`,
        next: 'shopkeeperCedric.wineDelivery5',
        function: () => {
          location.unlockEntrance('elarianCourtyard', 'goblinForestRight');
        },
      },
      goblinKingPayment: {
        text: `"Is an audience with the king not payment enough for you? I'm sorry, but I can't help you if you won't help me."`,
        options: [
          {
            display: 'Offer to help',
            text: `"Alright, I can do that for you. Where can I find the Goblin King?"`,
            next: 'castleGuard.askForCrown.goblinKingAccept',
          },
        ],
      },
      goblinKingWhy: {
        text: `"The Goblin King is too powerful for me to defeat. You were able to take care of the rat boss, so I thought you might be able to help."`,
        options: [
          {
            display: 'Offer to help',
            text: `"Alright, I can do that for you. Where can I find the Goblin King?"`,
            next: 'castleGuard.askForCrown.goblinKingAccept',
          },
          {
            display: 'Ask for payment',
            text: `"I could do that for you, but I will need some payment for my services."`,
            next: 'castleGuard.askForCrown.goblinKingPayment',
          },
        ],
      },
    },
    goblinKing: {
      text: `"I once saw the Goblin King. He was terrifying and ugly. I hope you can defeat him!"`,
    },
    goblinKing2: {
      text: `"The Goblin King is in the forest to the west of the castle's courtyard in the north. Good luck!"`,
    },
    talkToOtherGuard: {
      text: `"I'm just an intern, you should talk to the other guard."`,
    },
    crownDeliveryNoCedric: {
      text: `"You've retrieved the crown? That's excellent! But wait, where's Cedric? He was supposed to accompany you. You should go get him first."`,
      options: [
        {
          display: 'Go get Cedric',
          text: `"You're right, I'll go get him now."`,
        },
        {
          display: 'Insist on delivering the crown now',
          text: `"But I have the crown right here. Can't we just deliver it now?"`,
          next: 'castleGuard.crownDeliveryNoCedric.insist',
        },
      ],
      insist: {
        text: `"I'm sorry, but Cedric was instrumental in this whole affair. It wouldn't be right to leave him out. Please go fetch him from his shop."`,
      },
    },
    crownDeliveryNoCedricNoOptions: {
      text: `"Please go fetch Cedric from his shop. He was instrumental in this whole affair and it wouldn't be right to leave him out."`,
    },
    crownDeliveryWithCedric: {
      text: `"You've retrieved the crown? Excellent work! Please, both of you, follow me to the throne room."`,
      next: 'castleGuard.throneRoomIntroduction',
      continueFunction: async () => {
        // Move castle guard to the throne room.
        location.moveNpcLocation('Castle Guard 2', 'elarianCastle', 'elarianThroneRoom', 1, 2);
        await util.delay(250);

        // Move Cedric to the throne room.
        location.moveNpc('elarianCastle', 'Cedric', 'right');
        await util.delay(500);
        location.moveNpc('elarianCastle', 'Cedric', 'up');
        await util.delay(500);
        location.moveNpcLocation('Cedric', 'elarianCastle', 'elarianThroneRoom', 3, 2);

        // Move player to the throne room.
        await location.movePlayerTap(player.currentLocation, 2, 5, true);
        await location.movePlayerTap('elarianThroneRoom', 2, 2, true);
      },
    },
    throneRoomIntroduction: {
      text: `"Your Majesty, I present to you Cedric, the wine merchant, and Thaddeus, the brave adventurer who retrieved your crown."`,
      next: 'kingRehoboam.greeting',
      continueFunction: async () => {
        // Move the guard to the right of his original position.
        location.moveNpc('elarianThroneRoom', 'Castle Guard 2', 'down');
        await util.delay(250);
        location.moveNpc('elarianThroneRoom', 'Castle Guard 2', 'right');
        await util.delay(250);
        location.moveNpcLocation('Castle Guard 2', 'elarianThroneRoom', 'elarianCastle', 3, 4);
      },
    },
    afterCrown1: {
      text: `"Once I am done with my internship, I will be a full-fledged guard. I can't wait!"`,
    },
    afterCrown2: {
      text: `"Thanks again for getting the crown back. You're always welcome in the castle."`,
    },
  },
};

const getDefaultDialogue = () => {
  if (dialogue.checkDialogue('shopkeeperCedric.talk.cedricWine') && !dialogue.checkDialogue('castleGuard.wineDelivery')) {
    return 'castleGuard.wineDelivery';
  } else if (!dialogue.checkDialogue('castleGuard.wineDelivery') && !dialogue.checkDialogue('castleGuard.talk')) {
    return 'castleGuard.talk';
  }
};

const npcs = [
  {
    type: 'npc',
    location: 'elarianCastle',
    x: 1,
    y: 4,
    name: 'Castle Guard 1',
    displayName: 'Castle Guard',
    tileImage: 'guard',
    dialogueKey: 'castleGuard',
    dialogueFunction: () => {
      const defaultDialogue = getDefaultDialogue();
      if (defaultDialogue) return defaultDialogue;
      else if (player.inventory.find(item => item.name === "King's Crown") && !dialogue.checkDialogue('castleGuard.crownDeliveryWithCedric')) {
        return 'castleGuard.talkToOtherGuard';
      } else if (!dialogue.checkDialogue('castleGuard.wineDelivery')) return 'castleGuard.noEntry';
      else if (!dialogue.checkDialogue('castleGuard.crownDeliveryWithCedric')) return 'castleGuard.goblinKing';
      else return 'castleGuard.afterCrown1';
    },
  },
  {
    type: 'npc',
    location: 'elarianCastle',
    x: 2,
    y: 4,
    name: 'Castle Guard 2',
    displayName: 'Castle Guard',
    tileImage: 'guard',
    dialogueKey: 'castleGuard',
    // Shares dialogues with Castle Guard 1, since they have the same display name.
    dialogueFunction: () => {
      const defaultDialogue = getDefaultDialogue();
      if (defaultDialogue) return defaultDialogue;
      else if (player.inventory.find(item => item.name === "King's Crown") && !dialogue.checkDialogue('castleGuard.crownDeliveryWithCedric')) {
        if (!dialogue.checkDialogue('shopkeeperCedric.talk.gotCrown')) {
          if (!dialogue.checkDialogue('castleGuard.crownDeliveryNoCedric')) return 'castleGuard.crownDeliveryNoCedric';
          else return 'castleGuard.crownDeliveryNoCedricNoOptions';
        } else return 'castleGuard.crownDeliveryWithCedric';
      } else if (!dialogue.checkDialogue('castleGuard.wineDelivery')) return 'castleGuard.noEntry2';
      else if (!dialogue.checkDialogue('castleGuard.crownDeliveryWithCedric')) return 'castleGuard.goblinKing2';
      else return 'castleGuard.afterCrown2';
    },
  },
];

export const castleGuards = { npcs, dialogues };
