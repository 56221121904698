import { dialogue } from '../../dialogue.js';

const goblinForestCombat = {
  combatKey: 'goblinForest',
  encounterRate: 'low',
  enemies: [
    {
      name: 'goblet',
      tileImage: 'goblet',
      encounterDialogueFunction: () => {
        if (!dialogue.checkDialogue('goblinForest.firstGoblet')) return 'goblinForest.firstGoblet';
        return 'goblinForest.goblet';
      },
      defeatDialogueFunction: () => {
        if (!dialogue.checkDialogue('goblinForest.firstDefeatGoblet')) return 'goblinForest.firstDefeatGoblet';
        return 'goblinForest.defeatGoblet';
      },
      hp: 20,
      maxHp: 20,
      attack: 15,
      magicAttack: 15,
      defense: 15,
      magicDefense: 25,
    },
    {
      name: 'goblin',
      tileImage: 'goblin',
      encounterDialogueFunction: () => {
        if (!dialogue.checkDialogue('goblinForest.firstGoblin')) return 'goblinForest.firstGoblin';
        return 'goblinForest.goblin';
      },
      defeatDialogueFunction: () => {
        if (!dialogue.checkDialogue('goblinForest.firstDefeatGoblin')) return 'goblinForest.firstDefeatGoblin';
        return 'goblinForest.defeatGoblin';
      },
      hp: 30,
      maxHp: 30,
      attack: 25,
      magicAttack: 25,
      defense: 15,
      magicDefense: 15,
    },
    {
      name: 'ogre',
      tileImage: 'ogre',
      encounterDialogueFunction: () => {
        if (!dialogue.checkDialogue('goblinForest.firstOgre')) return 'goblinForest.firstOgre';
        return 'goblinForest.ogre';
      },
      defeatDialogueFunction: () => {
        if (!dialogue.checkDialogue('goblinForest.firstDefeatOgre')) return 'goblinForest.firstDefeatOgre';
        return 'goblinForest.defeatOgre';
      },
      hp: 50,
      maxHp: 50,
      attack: 35,
      magicAttack: 35,
      defense: 45,
      magicDefense: 25,
    },
  ],
};

export { goblinForestCombat };
