const dialogues = {
  kingGuard: {
    talk1: {
      text: `"Welcome to the throne room. Please show respect in the presence of His Majesty."`,
    },
    talk2: {
      text: `"It's an honor to stand in the presence of the hero who retrieved the king's crown. Your bravery brings hope to our kingdom."`,
    },
  },
};

const npcs = [
  {
    type: 'npc',
    location: 'elarianThroneRoom',
    x: 1,
    y: 5,
    name: "King's Guard 1",
    displayName: "King's Guard",
    tileImage: 'guard',
    dialogueKey: 'kingGuard',
    dialogueFunction: () => 'kingGuard.talk1',
  },
  {
    type: 'npc',
    location: 'elarianThroneRoom',
    x: 3,
    y: 5,
    name: "King's Guard 2",
    displayName: "King's Guard",
    tileImage: 'guard',
    dialogueKey: 'kingGuard',
    dialogueFunction: () => 'kingGuard.talk2',
  },
];

export const kingsGuards = { npcs, dialogues };
