import { util } from './util.js';
import { player } from './player.js';
import { location } from './location/index.js';
import { npc } from './npc/index.js';
import { dialogues as locationDialogues } from './location/dialogues.js';

export const dialogues = { ...locationDialogues, ...npc.dialogues };

const writeText = async (text, textElementId, npcName) => {
  // Clear option buttons when writing text.
  createOptionButtons();

  const characterElement = document.getElementById('characterDisplay');
  const textWrapper = document.getElementById('textDisplayWrapper');
  const textElement = document.getElementById(textElementId || 'textDisplay');
  if (textElementId) textElement.style.display = 'flex';
  textElement.innerHTML = '';

  if (npcName) {
    let thisImage = '';
    let thisName = '';
    if (npcName !== 'Thaddeus') {
      const thisNpc = npc.npcList.find(n => n.name === npcName);
      thisImage = location.images.npc[thisNpc.tileImage];
      thisName = thisNpc.displayName || thisNpc.name;
    } else {
      thisImage = location.images.thaddeus;
      thisName = 'Thaddeus';
    }

    const imageElement = document.createElement('img');
    imageElement.draggable = false;
    imageElement.src = thisImage;
    imageElement.id = 'characterImageDisplay';

    const name = thisName;
    const nameElement = document.createElement('div');
    nameElement.id = 'characterNameDisplay';
    nameElement.textContent = name;

    characterElement.innerHTML = '';
    characterElement.appendChild(imageElement);
    textElement.appendChild(nameElement);
    characterElement.style.display = 'block';
  } else {
    characterElement.innerHTML = '';
    characterElement.style.display = 'none';
  }

  if (!text) {
    textWrapper.style.display = 'none';
    return (textElement.style.display = 'none');
  } else {
    textWrapper.style.display = 'flex';
    textElement.style.display = 'flex';
  }

  textElement.innerHTML += '<p></p>';
  const pElement = textElement.lastChild;
  for (const char of text) {
    await util.delay(20);
    pElement.textContent += char;
    // Automatically scroll to the bottom of the text display.
    pElement.scrollTop = pElement.scrollHeight;
  }
};

// Display a list of options as buttons on the screen. If no options are provided, the screen will be cleared.
const createOptionButtons = (options, optionElementId) => {
  const optionElement = document.getElementById(optionElementId || 'optionDisplay');
  optionElement.innerHTML = '';
  if (options?.length) optionElement.style.display = 'flex';
  else return (optionElement.style.display = 'none');
  options?.forEach(option => {
    const button = document.createElement('div');
    button.classList.add('option');
    button.innerHTML = `<div class="text-with-padding">${option.display}</div>`;
    button.onclick = () => {
      option.function?.();
      if (option.text) displayDialogue(undefined, option);
      else if (option.next) displayDialogue(option.next);
    };
    optionElement.appendChild(button);
  });

  // Automatically scroll to the top of the option display.
  optionElement.scrollTop = 0;
};

const displayDialogue = async (dialogueName, thisDialogue) => {
  player.canExitDialogue = false;
  if (!thisDialogue && dialogueName) thisDialogue = getDialogue(dialogueName);
  if (thisDialogue) {
    let npcName = '';
    if (dialogueName) npcName = npc.npcList.find(n => n.dialogueKey === dialogueName.split('.')[0])?.name;
    else npcName = 'Thaddeus';

    await thisDialogue.prefunction?.();
    const text = thisDialogue.textFunction?.() || thisDialogue.text;
    await writeText(text, undefined, npcName || undefined);
    // Set the dialogue as seen before running the function so that when the game is saved, the dialogue is saved as seen.
    if (dialogueName) setDialogueSeen(dialogueName);
    await thisDialogue.function?.();

    if (thisDialogue.nextFunction) thisDialogue.next = thisDialogue.nextFunction();

    const filteredOptions = thisDialogue.options?.filter(option => checkRequirements(option.requires));

    if (filteredOptions?.length) createOptionButtons(filteredOptions);
    else if (thisDialogue.next || thisDialogue.continueFunction) {
      createOptionButtons([
        {
          display: 'Continue',
          function: async () => {
            await thisDialogue.continueFunction?.();
            if (thisDialogue.next) displayDialogue(thisDialogue.next);
          },
        },
      ]);
    } else if (!thisDialogue.manualOptions) createOptionButtons([{ display: 'Close', function: clearScreen }]);
  }
};

export const getDialogue = dialogueName => {
  let thisDialogue;
  const dialogueObject = dialogueName.split('.')[0];
  const dialogueKeys = dialogueName.split('.').slice(1);
  thisDialogue = dialogues[dialogueObject];
  dialogueKeys.forEach(key => {
    thisDialogue = thisDialogue?.[key];
  });

  // If no dialogue found, then close the text display so the player can continue playing.
  if (!thisDialogue) {
    console.error(`Dialogue not found: ${dialogueName}`);
    return clearScreen();
  }

  return thisDialogue;
};

const clearScreen = () => {
  writeText();
  createOptionButtons();
  player.canExitDialogue = true;
  location.checkNearbyInteraction();
};

const checkDialogue = dialogueName => {
  const dialogueObject = dialogueName.split('.')[0];
  const dialogueKey = dialogueName.split('.').slice(1).join('.');
  return player.dialogue[dialogueObject]?.[dialogueKey];
};

const setDialogueSeen = dialogueName => {
  const dialogueObject = dialogueName.split('.')[0];
  const dialogueKey = dialogueName.split('.').slice(1).join('.');
  player.dialogue[dialogueObject] = player.dialogue[dialogueObject] || {};
  player.dialogue[dialogueObject][dialogueKey] = true;
};

const checkRequirements = requirements => {
  if (!requirements) return true;
  if (requirements.and) return requirements.and.every(cond => evaluateCondition(cond));
  if (requirements.or) return requirements.or.some(cond => evaluateCondition(cond));
  return evaluateCondition(requirements);
};

const evaluateCondition = condition => {
  if (condition.inventory) return player.inventory.find(item => item.name === condition.inventory);
  if (condition.not) return !dialogue.checkDialogue(condition.not);
  if (condition.check) return dialogue.checkDialogue(condition.check);
  if (condition.and || condition.or) return checkRequirements(condition);
  return false;
};

export const dialogue = {
  writeText,
  createOptionButtons,
  displayDialogue,
  checkDialogue,
  setDialogueSeen,
  clearScreen,
};
