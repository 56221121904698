import { dialogue } from '../dialogue.js';

const dialogues = {
  fatherMichael: {
    talk: {
      textFunction: () => {
        if (!dialogue.checkDialogue('fatherMichael.talk')) return `"Welcome to the Elarian cathedral. I am Father Michael. How can I help you?"`;
        else return `"Is there anything I can help you with?"`;
      },
      options: [
        {
          requires: { not: 'fatherMichael.talk.about' },
          display: 'Ask about Michael',
          text: `"What do you do here, Father?"`,
          next: 'fatherMichael.talk.about',
        },
        {
          requires: { not: 'fatherMichael.talk.aboutCathedral' },
          display: 'Ask about the cathedral',
          text: `"Can you tell me more about the cathedral?"`,
          next: 'fatherMichael.talk.aboutCathedral',
        },
        {
          // If you already talked to Cedric about seeing the king, this option will not show.
          requires: { and: [{ not: 'shopkeeperCedric.talk.seeTheKing' }, { not: 'fatherMichael.talk.seeTheKing' }] },
          display: 'Ask about speaking with the king',
          text: `"Do you know how I could speak with the king?"`,
          next: 'fatherMichael.talk.seeTheKing',
        },
        {
          display: 'Ask for a prayer',
          text: `"Can you say a prayer for me?"`,
          next: 'fatherMichael.talk.prayer',
        },
        {
          display: 'Nevermind',
          function: () => {
            dialogue.clearScreen();
          },
        },
      ],
      about: {
        text: `"I am the head of the Elarian church and I am responsible for the spiritual well-being of the people."`,
      },
      aboutCathedral: {
        text: `"The cathedral was built hundreds of years ago. It is one of the oldest buildings in the city. The cathedral is a place of worship and a place of refuge for those in need."`,
      },
      seeTheKing: {
        text: `"If you wish to speak with the king, you should talk to Cedric, the shopkeeper. Cedric knows the king well and might be able to help."`,
      },
      prayer: {
        text: `"Of course. Which prayer would you like me to say for you?"`,
        options: [
          {
            display: 'Ask for a Hail Mary',
            text: `"A Hail Mary."`,
            next: 'fatherMichael.talk.prayer.hailMary',
          },
          {
            display: 'Ask for an Our Father',
            text: `"An Our Father."`,
            next: 'fatherMichael.talk.prayer.ourFather',
          },
          {
            display: 'Ask for a Glory Be',
            text: `"A Glory Be."`,
            next: 'fatherMichael.talk.prayer.gloryBe',
          },
          {
            display: 'Ask for a blessing',
            text: `"Can you give me a blessing, Father?"`,
            next: 'fatherMichael.talk.prayer.blessing',
          },
        ],
        hailMary: {
          text: `"Hail Mary, full of grace, the Lord is with thee; blessed art thou among women, and blessed is the fruit of thy womb, Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the hour of our death. Amen."`,
        },
        ourFather: {
          text: `"Our Father who art in heaven, hallowed be thy name. Thy kingdom come. Thy will be done on earth as it is in heaven. Give us this day our daily bread. And forgive us our trespasses, as we forgive those who trespass against us. And lead us not into temptation, but deliver us from evil. Amen."`,
        },
        gloryBe: {
          text: `"Glory be to the Father, and to the Son, and to the Holy Spirit. As it was in the beginning, is now, and ever shall be, world without end. Amen."`,
        },
        blessing: {
          text: `"May the Lord bless you and keep you. May the Lord make his face to shine upon you, and be gracious to you. May the Lord lift up his countenance upon you, and give you peace."`,
        },
      },
    },
  },
};

const npc = {
  type: 'npc',
  location: 'elarianCathedral',
  x: 2,
  y: 4,
  name: 'Father Michael',
  tileImage: 'fatherMichael',
  dialogueKey: 'fatherMichael',
  dialogueFunction: () => 'fatherMichael.talk',
};

export const fatherMichael = { npc, dialogues };
