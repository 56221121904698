export const player = {
  // Version is used to track the version of the save data. If the structure of the save data changes, the version number should be updated.
  // Important: Only update the version number here for breaking changes.
  version: '0.2.0',
  saveId: 0, // 0, 1, and 2 are the save slots. Save slot logic will be added later.
  gold: 0,
  hp: 35,
  maxHp: 35,
  mp: 0,
  maxMp: 0,
  attack: 25,
  magicAttack: 25,
  defense: 25,
  magicDefense: 25,
  combat: {
    shopCellar: {
      tilesToEnemy: 4,
    },
  },
  // The dialogue object is created dynamically. It tracks the player's progress within dialogue chains.
  dialogue: {},
  canExitDialogue: false,
  // The modifiedTiles object is created dynamically. It tracks the player's progress within locations.
  modifiedTiles: {},
  modifiedNpcs: [],
  currentLocation: 'elarianGates',
  hideGold: true,
  hideHealth: true,
  hideMagic: true,
  inventory: [],
  x: 2,
  y: 0,
};
