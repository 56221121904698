import { dialogue } from '../../dialogue.js';
import { util } from '../../util.js';
import { shopCellarDialogues } from './shopCellar/dialogues.js';

const elarianDialogues = {
  ...shopCellarDialogues,
  elarianGates: {
    intro: {
      text: `You are Thaddeus, a peasant from a small village on the outskirts of the kingdom of Glenfall.
      Ever since you were a kid, you have dreamed of being able to save Glenfall from The Dark One who has been terrorizing the kingdom for centuries.
      You finally saved up enough as a butcher's apprentice to buy a ride to Elarian, Glenfall's capital city.
      You hope to be able to speak with the king and get his blessing on your quest to defeat The Dark One.`,
      next: 'elarianGates.intro2',
    },
    intro2: {
      text: `After a long journey, you finally arrive at the city gates. Use the arrow keys, wasd, or click any tile to move.`,
    },
  },
  elarianShop: {
    lockedCellar: {
      text: 'The cellar is locked.',
    },
  },
  elarianTower: {
    faint: {
      text: `Everything goes black... You slowly regain consciousness.`,
      nextFunction: () => {
        if (!dialogue.checkDialogue('wizardEldwin.firstFaint')) return 'wizardEldwin.firstFaint';
        else return 'wizardEldwin.faint';
      },
    },
  },
  elarianCourtyard: {
    investigateDragonStatue: {
      text: `The text on the statue reads: "Here lies the great dragon, protector of Glenfall. May his spirit forever watch over us."
      It looks like the statue has a small slit near the base, just big enough to fit your sword.`,
      options: [{ display: 'Put your sword in the slit', next: 'elarianCourtyard.firstSaveGame' }],
    },
    firstSaveGame: {
      text: `You put your sword in the slit. The ground trembles slightly, and you feel a wave of assurance that you will no longer have to worry about losing your progress. 
        Your game has been saved! Return to this statue to save your game at any time.`,
      // Save the game after the dialogue is finished for the first time, so that the fact the dialogue has been seen is saved.
      function: () => {
        util.saveGame();
      },
    },
    saveGame: {
      text: `You put your sword in the slit. Your game has been saved!`,
      // Save the game before the dialogue is shown, since that leaves less room for the player to close the game before the save is complete.
      prefunction: () => {
        util.saveGame();
      },
    },
  },
};

export { elarianDialogues };
