import { goblinForestCombat } from './combat.js';

const goblinForestLocations = [
  {
    name: 'goblinForestRight',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 8, y: 3, location: 'elarianCourtyard', outputDirection: 'left' },
      { type: 'entrance', x: 0, y: 3, location: 'goblinForestCenter', outputDirection: 'right' },
    ],
  },
  {
    name: 'goblinForestCenter',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 8, y: 3, location: 'goblinForestRight', outputDirection: 'left' },
      { type: 'entrance', x: 4, y: 0, location: 'goblinForestBottom1', outputDirection: 'up' },
      { type: 'entrance', x: 4, y: 6, location: 'goblinForestTop1', outputDirection: 'down' },
      { type: 'entrance', x: 0, y: 3, location: 'goblinForestLeft1', outputDirection: 'right' },
    ],
  },
  {
    name: 'goblinForestBottom1',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 4, y: 6, location: 'goblinForestCenter', outputDirection: 'down' },
      { type: 'entrance', x: 4, y: 0, location: 'goblinForestBottom2', outputDirection: 'up' },
    ],
  },
  {
    name: 'goblinForestTop1',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 4, y: 0, location: 'goblinForestCenter', outputDirection: 'up' },
      { type: 'entrance', x: 4, y: 6, location: 'goblinForestTop2', outputDirection: 'down' },
    ],
  },
  {
    name: 'goblinForestLeft1',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 8, y: 3, location: 'goblinForestCenter', outputDirection: 'left' },
      { type: 'entrance', x: 0, y: 3, location: 'goblinForestLeft2', outputDirection: 'right' },
    ],
  },
  {
    name: 'goblinForestBottom2',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 4, y: 6, location: 'goblinForestBottom1', outputDirection: 'down' },
      {
        type: 'object',
        x: 1,
        y: 2,
        tileImage: 'chest',
        dialogueFunction: () => 'goblinForest.treasureBottom1',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
      {
        type: 'object',
        x: 7,
        y: 2,
        tileImage: 'chest',
        dialogueFunction: () => 'goblinForest.treasureBottom2',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
    ],
  },
  {
    name: 'goblinForestTop2',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 4, y: 0, location: 'goblinForestTop1', outputDirection: 'up' },
      {
        type: 'object',
        x: 1,
        y: 5,
        tileImage: 'chest',
        dialogueFunction: () => 'goblinForest.treasureTop1',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
      {
        type: 'object',
        x: 7,
        y: 5,
        tileImage: 'chest',
        dialogueFunction: () => 'goblinForest.treasureTop2',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
    ],
  },
  {
    name: 'goblinForestLeft2',
    displayName: 'Goblin Forest',
    width: 9,
    height: 7,
    pathImage: 'forestGrass',
    wallObject: { type: 'object', tileImage: 'tree', noMovement: true },
    walls: ['left', 'right', 'top', 'bottom'],
    combat: { ...goblinForestCombat },
    tiles: [
      { type: 'entrance', x: 8, y: 3, location: 'goblinForestLeft1', outputDirection: 'left' },
      { type: 'entrance', x: 7, y: 5, location: 'goblinCamp', outputDirection: 'down', tileImage: 'goblinCamp' },
      {
        type: 'object',
        x: 1,
        y: 2,
        tileImage: 'chest',
        dialogueFunction: () => 'goblinForest.treasureLeft1',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
      {
        type: 'object',
        x: 1,
        y: 5,
        tileImage: 'chest',
        dialogueFunction: () => 'goblinForest.treasureLeft2',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
    ],
  },
  {
    name: 'goblinCamp',
    displayName: 'Goblin Camp',
    width: 5,
    height: 6,
    pathImage: 'forestGrass',
    tiles: [
      { type: 'entrance', x: 2, y: 0, location: 'goblinForestLeft2', outputDirection: 'up' },
      { type: 'empty', x: 0, y: 0 },
      { type: 'empty', x: 1, y: 0 },
      { type: 'empty', x: 3, y: 0 },
      { type: 'empty', x: 4, y: 0 },
    ],
  },
];

export { goblinForestLocations };
