import { util } from './util.js';
import { dialogue } from './dialogue.js';
import { location } from './location/index.js';
import { player } from './player.js';

const gameVersion = '0.2.1';

window.onload = async () => {
  mainMenu();
  location.setupMovementListener();

  // Display the beta tile and version number.
  const betaTile = document.getElementById('betaTile');
  const version = document.getElementById('version');
  version.innerText = `BETA v${gameVersion}`;
  betaTile.style.display = 'block';
};

const mainMenu = () => {
  const saves = JSON.parse(localStorage.getItem('save'));
  document.getElementById('menuTextDisplay').style.display = 'none';
  if (saves?.length && saves[0].version && saves[0].version == player.version) {
    dialogue.createOptionButtons(
      [
        {
          display: 'Continue',
          function: () => {
            showGame();
            util.loadSave();
          },
        },
        {
          display: 'New Game',
          function: () => {
            confirmReset();
          },
        },
      ],
      'menuOptionDisplay',
    );
  } else {
    dialogue.createOptionButtons(
      [
        {
          display: 'New Game',
          function: () => {
            showGame();
            location.displayLocation('elarianGates');
            dialogue.displayDialogue('elarianGates.intro');
          },
        },
      ],
      'menuOptionDisplay',
    );
  }
};

const confirmReset = async () => {
  dialogue.createOptionButtons([], 'menuOptionDisplay');
  await dialogue.writeText('Are you sure you want to reset your game?', 'menuTextDisplay');
  dialogue.createOptionButtons(
    [
      {
        display: 'Yes',
        function: () => {
          localStorage.removeItem('save');
          showGame();
          location.displayLocation('elarianGates');
          dialogue.displayDialogue('elarianGates.intro');
        },
      },
      {
        display: 'No',
        function: () => {
          mainMenu();
        },
      },
    ],
    'menuOptionDisplay',
  );
};

const showGame = () => {
  document.getElementById('menu').style.display = 'none';

  const gameDisplay = document.getElementById('game');
  gameDisplay.style.display = 'flex';
};
