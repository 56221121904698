import { cellarCombat } from './combat.js';

const shopCellarLocations = [
  {
    name: 'shopCellarEntrance',
    displayName: "Cedric's Cellar",
    width: 7,
    height: 7,
    pathImage: 'cobblestone',
    wallImage: 'stoneBrick',
    combatKey: 'shopCellar',
    combat: { ...cellarCombat },
    walls: ['top'],
    tiles: [
      { type: 'entrance', x: 3, y: 0, tileImage: 'cellarDoor', location: 'elarianShop', outputDirection: 'up' },
      { type: 'entrance', x: 3, y: 6, tileImage: 'door', location: 'shopCellarBossRoom', tileBackground: 'wall', outputDirection: 'down' },
      {
        type: 'object',
        x: 0,
        y: 5,
        tileImage: 'chest',
        dialogueFunction: () => 'shopCellar.treasure1',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
      {
        type: 'object',
        x: 6,
        y: 5,
        tileImage: 'chest',
        dialogueFunction: () => 'shopCellar.treasure2',
        displayFunction: () => 'Open the chest',
        noMovement: true,
      },
    ],
  },
  {
    name: 'shopCellarBossRoom',
    displayName: "Cedric's Cellar",
    width: 5,
    height: 7,
    pathImage: 'cobblestone',
    wallImage: 'stoneBrick',
    combatKey: 'shopCellar',
    combat: { ...cellarCombat },
    walls: ['top', 'bottom'],
    tiles: [
      { type: 'entrance', x: 2, y: 0, tileImage: 'door', location: 'shopCellarEntrance', tileBackground: 'wall', outputDirection: 'up' },
      { type: 'entrance', x: 2, y: 6, tileImage: 'door', location: 'shopCellarWineRoom', tileBackground: 'wall', outputDirection: 'down' },
    ],
  },
  {
    name: 'shopCellarWineRoom',
    displayName: "Cedric's Wine Room",
    width: 3,
    height: 4,
    pathImage: 'cobblestone',
    wallImage: 'stoneBrick',
    walls: ['bottom'],
    tiles: [
      { type: 'entrance', x: 1, y: 0, tileImage: 'door', location: 'shopCellarBossRoom', tileBackground: 'wall', outputDirection: 'up' },
      {
        type: 'object',
        x: 1,
        y: 2,
        tileImage: 'chest',
        dialogueFunction: () => 'shopCellar.wine',
        displayFunction: () => 'Take the wine',
        noMovement: true,
      },
    ],
  },
];

export { shopCellarLocations };
