import { fatherMichael } from './fatherMichael.js';
import { shopkeeperCedric } from './shopkeeperCedric.js';
import { wizardEldwin } from './wizardEldwin.js';
import { jesterJeffrey } from './jesterJeffrey.js';
import { kingRehoboam } from './kingRehoboam.js';
import { guards } from './guards/index.js';
import { ratBoss } from './ratBoss.js';
import { goblinKing } from './goblinKing.js';
import { goblins } from './goblins.js';

const dialogues = {
  ...fatherMichael.dialogues,
  ...shopkeeperCedric.dialogues,
  ...wizardEldwin.dialogues,
  ...jesterJeffrey.dialogues,
  ...kingRehoboam.dialogues,
  ...ratBoss.dialogues,
  ...guards.dialogues,
  ...goblinKing.dialogues,
  ...goblins.dialogues,
};

const npcList = [
  fatherMichael.npc,
  shopkeeperCedric.npc,
  wizardEldwin.npc,
  jesterJeffrey.npc,
  kingRehoboam.npc,
  ...guards.npcList,
  ratBoss.npc,
  goblinKing.npc,
  ...goblins.npcs,
];

export const npc = { npcList, dialogues };
