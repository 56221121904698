import { location } from '../../location/index.js';
import { dialogue } from '../../dialogue.js';

const dialogues = {
  elarianGuard: {
    talk: {
      text: `"Halt! Who are you and what brings you to the city of the great king?"`,
      options: [
        {
          display: 'Give your name and tell him your true purpose',
          text: `"I am Thaddeus, son of Bartholomew. I am here to seek an audience with the king. I would like his blessing on my quest to defeat The Dark One."`,
          next: 'elarianGuard.talk.seeTheKing',
        },
        {
          display: 'Give a fake name and say you are a tourist',
          text: `"I am Coriander, son of Terrence. I am a traveller from Adria visiting Glenfall for the first time. I have heard about the beauty of Elarian and would like to see it for myself."`,
          next: 'elarianGuard.talk.seeTheCity',
        },
      ],
      seeTheKing: {
        text: `"Ah, nice to meet you, Thaddeus. Welcome to Elarian! If you are looking to speak with the king, you might want to talk with the shopkeeper, Cedric, first. He knows the king well and might be able to help you."`,
        function: () => {
          location.moveNpc('elarianGates', 'Elarian Guard 2', 'right');
        },
      },
      seeTheCity: {
        text: `"Hmm... You look a lot like a Glenfallian. Anyways, nice to meet you, Coriander. Welcome to Elarian! If you are looking to explore the city, I recommend you start at the cathedral. It is the most beautiful building in the city."`,
        function: () => {
          location.moveNpc('elarianGates', 'Elarian Guard 2', 'right');
        },
      },
    },
    postIntro: {
      text: `"Have a great time in Elarian!"`,
    },
    postIntro2: {
      text: `"Hope you find what you're looking for in Elarian!"`,
    },
  },
};

const getDefaultDialogue = () => {
  if (!dialogue.checkDialogue('elarianGuard.talk')) return 'elarianGuard.talk';
  else return;
};

const npcs = [
  {
    type: 'npc',
    location: 'elarianGates',
    x: 1,
    y: 4,
    name: 'Elarian Guard 1',
    displayName: 'Elarian Guard',
    tileImage: 'guard',
    dialogueKey: 'elarianGuard',
    dialogueFunction: () => {
      const defaultDialogue = getDefaultDialogue();
      if (defaultDialogue) return defaultDialogue;
      else return 'elarianGuard.postIntro';
    },
  },
  {
    type: 'npc',
    location: 'elarianGates',
    x: 2,
    y: 4,
    name: 'Elarian Guard 2',
    displayName: 'Elarian Guard',
    tileImage: 'guard',
    dialogueKey: 'elarianGuard',
    // Shares dialogues with Elarian Guard 1, since they have the same display name.
    dialogueFunction: () => {
      const defaultDialogue = getDefaultDialogue();
      if (defaultDialogue) return defaultDialogue;
      else return 'elarianGuard.postIntro2';
    },
  },
];

export const elarianGuards = { npcs, dialogues };
