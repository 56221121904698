import { player } from '../player.js';
import { dialogue } from '../dialogue.js';
import { combat } from '../combat.js';
import { location } from '../location/index.js';
import { util } from '../util.js';

const initiateCombat = () => {
  // Need to spread the npc object to avoid modifying the original object.
  player.currentEnemy = { ...npc };
  location.displayLocation('shopCellarBossRoom');
};

const dialogues = {
  ratBoss: {
    intro: {
      text: `"I am the rat boss! Welcome to my lair! What do you want?"`,
      options: [
        {
          display: 'Tell him to you are here for the wine',
          text: `"Cedric sent me to get his wine from his cellar. This is not your lair."`,
          next: 'ratBoss.intro.wine',
        },
      ],
      wine: {
        text: `"Cedric sent you? That coward! I will not let you take the wine!"`,
        continueFunction: initiateCombat,
      },
    },
    nextVisit: {
      text: `"You again? I thought I took care of you. Do you still want the wine?"`,
      options: [
        {
          display: 'Yes',
          text: `"Yes, I am here for the wine. You won't be able to stop me this time."`,
          continueFunction: initiateCombat,
        },
      ],
    },
    encounter: {
      textFunction: () => {
        if (!dialogue.checkDialogue('ratBoss.encounter')) return `"You will not leave this place alive!"`;
        return `"Prepare to meet your doom!"`;
      },
      next: 'shopCellar.combat',
    },
    defeat: {
      text: `"No! How could I be defeated by a mere human? I will have my revenge!"`,
      options: [{ display: 'Return to map', function: () => combat.returnToMap(), next: 'ratBoss.defeat.defeated' }],
      defeated: {
        text: `"You have not seen the last of me. I will return stronger than ever!"`,
        function: async () => {
          // The rat boss will walk out of the cellar.
          await util.delay(250);
          location.moveNpc('shopCellarBossRoom', 'Rat Boss', 'down');
          await util.delay(250);
          location.moveNpc('shopCellarBossRoom', 'Rat Boss', 'down');
          await util.delay(250);
          location.moveNpc('shopCellarBossRoom', 'Rat Boss', 'down');
          await util.delay(250);
          location.moveNpc('shopCellarBossRoom', 'Rat Boss', 'down');
          await util.delay(250);

          location.moveNpcLocation('Rat Boss', 'shopCellarBossRoom', 'shopCellarBossRoom', -1, -1);
        },
      },
    },
  },
};

const npc = {
  type: 'npc',
  location: 'shopCellarBossRoom',
  x: 2,
  y: 5,
  name: 'Rat Boss',
  tileImage: 'ratBoss',
  dialogueKey: 'ratBoss',
  dialogueFunction: () => {
    if (!dialogue.checkDialogue('ratBoss.intro')) return 'ratBoss.intro';
    else return 'ratBoss.nextVisit';
  },
  encounterDialogueFunction: () => 'ratBoss.encounter',
  defeatDialogueFunction: () => 'ratBoss.defeat',
  hp: 40,
  maxHp: 40,
  attack: 30,
  magicAttack: 30,
  defense: 35,
  magicDefense: 20,
};

export const ratBoss = { npc, dialogues };
