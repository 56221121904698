import { dialogue } from '../dialogue.js';

const dialogues = {
  jesterJeffrey: {
    talk: {
      textFunction: () => {
        if (!dialogue.checkDialogue('jesterJeffrey.talk')) return `"I'm Jester Jeffrey. Do you want to hear a joke?"`;
        return `"Do you want to hear a joke?"`;
      },
      options: [
        {
          display: 'Yes',
          text: `"Sure, why not?"`,
          next: 'jesterJeffrey.talk.joke',
        },
        {
          display: 'No',
          text: `"Not really in the mood for jokes right now."`,
          next: 'jesterJeffrey.talk.noJoke',
        },
      ],
      joke: {
        textFunction: () => {
          const jokes = [
            `"What's a monarch's favorite type of precipitation? Reign!"`,
            `"Why did the peasant bring a ladder to the tavern? He heard the drinks were on the house!"`,
            `"What do you call a knight who's afraid to fight? Sir Render!"`,
            `"Why did the king go to the dentist? To get his teeth crowned!"`,
            `"What's a wizard's favorite subject in school? Spelling!"`,
            `"Three dragons walk into a tavern... There were no survivors."`,
            `"Why are knights always so tired? Because they work the knight shift!"`,
            `"What do you call a horse that lives next door? A neigh-bor!"`,
            `"Why did the castle break up with the drawbridge? It couldn't handle the ups and downs of the relationship!"`,
            `"Why were the early days of the kingdom called the Dark Ages? Because there were so many knights!"`,
          ];
          return jokes[Math.floor(Math.random() * jokes.length)];
        },
      },
      noJoke: {
        text: `"That's a shame. I have some good ones."`,
      },
    },
  },
};

const npc = {
  type: 'npc',
  location: 'elarianThroneRoom',
  x: 4,
  y: 1,
  name: 'Jeffrey',
  tileImage: 'jesterJeffrey',
  dialogueKey: 'jesterJeffrey',
  dialogueFunction: () => 'jesterJeffrey.talk',
};

export const jesterJeffrey = { npc, dialogues };
