import { dialogue } from '../dialogue.js';
import { location } from '../location/index.js';
import { util } from '../util.js';
import { player } from '../player.js';

const shopItems = [
  {
    name: 'bladeSharpener',
    displayName: 'blade sharpener',
    price: 100,
    text: `"The blade sharpener is a must-have for any adventurer. It will make your sword sharper and more effective in battle." (Attack +10)`,
    oneTime: true,
    buyFunction: () => {
      player.attack += 10;
    },
  },
  {
    name: 'magicGloves',
    displayName: 'magic gloves',
    price: 100,
    text: `"The magic gloves will increase your magic power and make your spells more effective." (Magic Attack +10)`,
    oneTime: true,
    buyFunction: () => {
      player.magicAttack += 10;
    },
  },
  {
    name: 'armorPolish',
    displayName: 'armor polish',
    price: 150,
    text: `"The armor polish will make your armor more durable and resistant to enemy attacks." (Defense +10)`,
    oneTime: true,
    buyFunction: () => {
      player.defense += 10;
    },
  },
  {
    name: 'healthElixir',
    displayName: 'health elixir',
    price: 200,
    text: `"The health elixir will increase your maximum health." (Max HP +10)`,
    oneTime: true,
    buyFunction: () => {
      player.maxHp += 10;
      player.hp += 10;
      util.updateHealthBar();
    },
  },
  {
    name: 'ogreSpray',
    displayName: 'ogre spray',
    price: 25,
    text: `"The ogre spray can be used to cause an ogre to flee from battle. It is a necessity if you plan on traveling through the forest. You can use it up to three times."`,
    buyFunction: () => {
      player.inventory.push({
        name: 'Ogre Spray',
        type: 'combatItem',
        uses: 3,
        useDialogue: 'goblinForest.ogreSpray',
      });
    },
  },
];

const buyOptions = [
  ...shopItems.map(item => ({
    ...(item.oneTime ? { requires: { not: `shopkeeperCedric.talk.buy.buy${item.name}` } } : {}),
    display: `Ask about the ${item.displayName} (${item.price} gold)`,
    text: `"Tell me about the ${item.displayName}."`,
    next: `shopkeeperCedric.talk.buy.${item.name}`,
  })),
  {
    display: 'Nevermind',
    function: () => {
      dialogue.clearScreen();
    },
  },
];

const dialogues = {
  shopkeeperCedric: {
    talk: {
      textFunction: () => {
        if (!dialogue.checkDialogue('shopkeeperCedric.talk'))
          return `"Welcome! Cedric at your service, shopkeeper extraordinaire. What can I do for you today?"`;
        return `"Is there anything I can help you with?"`;
      },
      options: [
        {
          // Only show this option if directed here from the guards or the priest.
          requires: {
            and: [
              { not: 'shopkeeperCedric.talk.seeTheKing' },
              { or: [{ check: 'elarianGuard.talk.seeTheKing' }, { check: 'fatherMichael.talk.seeTheKing' }] },
            ],
          },
          display: 'Ask about speaking with the king',
          text: `"Do you know how I could speak with the king?"`,
          next: 'shopkeeperCedric.talk.seeTheKing',
        },
        {
          // You need to retrieve the wine from the cellar before this option will show.
          requires: { and: [{ check: 'shopCellar.wine' }, { not: 'shopkeeperCedric.talk.cedricWine' }] },
          display: 'Tell him that you have retrieved the wine',
          text: `"You didn't tell me about the rat boss in your cellar. I took care of him and retrieved the wine."`,
          next: 'shopkeeperCedric.talk.cedricWine',
        },
        {
          requires: { and: [{ inventory: "King's Crown" }, { not: 'shopkeeperCedric.talk.gotCrown' }] },
          display: 'Tell him that you have retrieved the crown',
          text: `"I've retrieved the king's crown from the Goblin King."`,
          next: 'shopkeeperCedric.talk.gotCrown',
        },
        {
          display: 'Ask what he has for sale',
          text: `"What do you have for sale?"`,
          nextFunction: () => {
            if (dialogue.checkDialogue('shopkeeperCedric.talk.cedricWine')) return 'shopkeeperCedric.talk.buy';
            else return 'shopkeeperCedric.talk.cantBuy';
          },
        },
        {
          display: 'Nevermind',
          function: () => {
            dialogue.clearScreen();
          },
        },
      ],
      seeTheKing: {
        text: `"I wish I could help you, but I don't know how to speak with the king. I'm just a humble shopkeeper."`,
        options: [
          {
            display: 'Ask why you were told he would be able to help',
            text: `"I was told specifically to speak with you about seeing the king."`,
            next: 'shopkeeperCedric.talk.seeTheKing.askWhy',
          },
        ],
        askWhy: {
          text: `"I'm not sure why you were told that. I'm just a humble shopkeeper."`,
          options: [
            {
              display: 'Insist that he must know something',
              text: `"They wouldn't have sent me to you for no reason. Please, tell me how I can see the king."`,
              next: 'shopkeeperCedric.talk.seeTheKing.askWhy.insist',
            },
            {
              display: 'Answer sarcastically',
              text: `"I get it. You're just a humble shopkeeper. But seriously, how can I see the king?"`,
              next: 'shopkeeperCedric.talk.seeTheKing.askWhy.insist',
            },
          ],
          insist: {
            text: `"Alright, fine. I used to be on good terms with the king, but that was back when I supplied his wine. Ever since the rats infested my cellar, I haven't been able to reach my vintage wine supply. If I had the wine, I could probably get you an audience with the king."`,
            options: [
              {
                display: 'Offer to retrieve the wine from the cellar',
                text: `"I have dealt with rats before. I can retrieve the wine for you."`,
                next: 'shopkeeperCedric.talk.seeTheKing.askWhy.insist.retrieveWine',
              },
              {
                display: 'Ask if there is another way to get wine',
                text: `"Your cellar can't be the only place to get wine. Is there another option?"`,
                next: 'shopkeeperCedric.talk.seeTheKing.askWhy.insist.anotherWay',
              },
            ],
            anotherWay: {
              text: `"My wine is the best in the city. The king won't accept anything less. If you can't get the wine from my cellar, you might as well give up on seeing the king."`,
              options: [
                {
                  display: 'Offer to retrieve the wine from the cellar',
                  text: `"Alright, I'll get the wine from your cellar."`,
                  next: 'shopkeeperCedric.talk.seeTheKing.askWhy.insist.retrieveWine',
                },
                {
                  display: 'Offer to retrieve the wine from the cellar (sarcastic)',
                  text: `"I didn't realize a humble shopkeeper such as yourself had the best wine in the city. I'll get it for you."`,
                  next: 'shopkeeperCedric.talk.seeTheKing.askWhy.insist.retrieveWine',
                },
              ],
            },
            retrieveWine: {
              text: `"You would do that? Oh, thank you! The cellar is just down the stairs. Be careful, though... The rats are vicious."`,
              function: () => {
                location.unlockEntrance('elarianShop', 'shopCellarEntrance');
              },
            },
          },
        },
      },
      cantBuy: {
        text: `"Sorry, we are out of stock right now. The rats have eaten everything. We should be getting a fresh shipment soon."`,
      },
      buy: {
        text: `"Here, take a look! Is there anything you are interested in?"`,
        options: buyOptions,
        ...shopItems.reduce((acc, item) => {
          acc[item.name] = {
            text: item.text,
            options: [
              {
                display: `Buy the ${item.displayName} (${item.price} gold)`,
                text: `"I'll take it!"`,
                nextFunction: () => {
                  if (player.gold < item.price) return `shopkeeperCedric.talk.buy.cantBuy${item.name}`;
                  else return `shopkeeperCedric.talk.buy.buy${item.name}`;
                },
              },
              {
                display: 'Back',
                text: `"I'll think about it."`,
                next: 'shopkeeperCedric.talk.buyQuestionOnly',
              },
            ],
          };

          acc[`cantBuy${item.name}`] = {
            text: `"You don't have enough gold for that."`,
            next: 'shopkeeperCedric.talk.buyQuestionOnly',
          };

          acc[`buy${item.name}`] = {
            text: `"Thank you for your purchase!"`,
            function: () => {
              util.updateGold(-item.price);
              item.buyFunction();
            },
            next: 'shopkeeperCedric.talk.buyQuestionOnly',
          };

          return acc;
        }, {}),
      },
      buyQuestionOnly: {
        text: `"Is there anything you are interested in?"`,
        options: buyOptions,
      },
      cedricWine: {
        text: `"Oh, did I forget to mention that? I thought I said something about that vicious rat.
          Anyways... let's go deliver this to the king together! I will meet you at the castle."`,
        function: async () => {
          // Move Cedric over two, then down three tiles, with a short delay between each move. Then move Cedric to the castle.
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'right');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'right');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'down');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'down');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'down');
          await util.delay(500);

          location.moveNpcLocation('Cedric', 'elarianShop', 'elarianCastle', 1, 3);
        },
      },
      gotCrown: {
        text: `"You've retrieved the crown? That's fantastic! We should head to the castle right away to deliver it to the king."`,
        function: async () => {
          // Move Cedric to the castle, similar to the wine delivery scene
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'right');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'right');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'down');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'down');
          await util.delay(500);
          location.moveNpc('elarianShop', 'Cedric', 'down');
          await util.delay(500);

          location.moveNpcLocation('Cedric', 'elarianShop', 'elarianCastle', 1, 3);
        },
      },
    },
    preWineDelivery: {
      text: `"We should talk to the guards so they can let us in."`,
    },
    wineDelivery: {
      text: `"I'm great! I finally got my hands on some more of the king's favorite wine. 
        My friend here took care of the rat boss that was in my cellar and retrieved it for me."`,
      next: 'castleGuard.wineDelivery2',
    },
    wineDelivery2: {
      text: `"Well, I was actually hoping to deliver it in person. I haven't seen the king in a while and my friend here would like to meet him."`,
      next: 'castleGuard.wineDelivery3',
    },
    wineDelivery3: {
      text: `"Is there any way you could make an exception? I'm sure the king would be happy to see me."`,
      next: 'castleGuard.wineDelivery4',
    },
    wineDelivery4: {
      text: `"Yes, he did! What do you need help with?"`,
      next: 'castleGuard.askForCrown',
    },
    wineDelivery5: {
      text: `"Good luck, Thaddeus! I will head back to my shop. You might need to buy some items from me before you head out."`,
      function: async () => {
        await util.delay(500);
        location.moveNpc('elarianCastle', 'Cedric', 'down');
        await util.delay(500);
        location.moveNpc('elarianCastle', 'Cedric', 'down');
        await util.delay(500);
        location.moveNpc('elarianCastle', 'Cedric', 'right');
        await util.delay(500);

        location.moveNpcLocation('Cedric', 'elarianCastle', 'elarianShop', 0, 4);
      },
    },
    preCrownDelivery: {
      text: `"We should talk to the guards so they can let us in."`,
    },
    throneRoomWineDelivery: {
      text: `"Your Majesty, it brings me great joy to present you with your favorite vintage. I hope it meets your expectations as always."`,
      next: 'kingRehoboam.wineResponse',
    },
    throneRoomDeparture: {
      text: `"Thank you, Your Majesty. Thaddeus, I'll be heading back to my shop now. Feel free to visit if you need any supplies for your quest."`,
      function: async () => {
        // Move Cedric out of the throne room
        await util.delay(500);
        location.moveNpc('elarianThroneRoom', 'Cedric', 'down');
        await util.delay(500);
        location.moveNpc('elarianThroneRoom', 'Cedric', 'left');
        await util.delay(500);
        location.moveNpcLocation('Cedric', 'elarianThroneRoom', 'elarianShop', 0, 4);
      },
    },
  },
};

const npc = {
  type: 'npc',
  location: 'elarianShop',
  x: 0,
  y: 4,
  name: 'Cedric',
  tileImage: 'shopkeeperCedric',
  dialogueKey: 'shopkeeperCedric',
  dialogueFunction: () => {
    if (dialogue.checkDialogue('shopkeeperCedric.talk.cedricWine') && !dialogue.checkDialogue('castleGuard.wineDelivery')) {
      return 'shopkeeperCedric.preWineDelivery';
    } else if (dialogue.checkDialogue('shopkeeperCedric.talk.gotCrown') && !dialogue.checkDialogue('castleGuard.crownDeliveryWithCedric')) {
      return 'shopkeeperCedric.preCrownDelivery';
    } else return 'shopkeeperCedric.talk';
  },
};

export const shopkeeperCedric = { npc, dialogues };
