const dialogues = {
  kingRehoboam: {
    greeting: {
      text: `"Ah, Cedric, my old friend! And Thaddeus, the hero of the hour. Welcome to both of you."`,
      next: 'shopkeeperCedric.throneRoomWineDelivery',
    },
    wineResponse: {
      text: `"Cedric, your wine never disappoints. I look forward to savoring it. Now, I believe there's a more pressing matter at hand?"`,
      next: 'kingRehoboam.crownRequest',
    },
    crownRequest: {
      text: `"Thaddeus, I understand you have retrieved something of great importance to me and this kingdom."`,
      options: [
        {
          display: 'Present the crown to the king',
          text: `"Your Majesty, I have retrieved your crown from the Goblin King. It is my honor to return it to you."`,
          next: 'kingRehoboam.crownRequest.crownResponse',
        },
        {
          display: 'Present the crown to the king (informal)',
          text: `"Yep! I got your crown back from the Goblin King. Here you go."`,
          next: 'kingRehoboam.crownRequest.crownResponse',
        },
      ],
      crownResponse: {
        text: `"My crown! You have done this kingdom a great service. You have my deepest gratitude. Now, the Goblin King is a crafty foe. Might I ask how you managed to retrieve it?"`,
        options: [
          {
            display: 'Say that you defeated the Goblin King in combat',
            text: `"It was a fierce battle, but I defeated the Goblin King in combat and took the crown from him."`,
            next: 'kingRehoboam.crownRequest.crownResponse.goblin',
          },
          {
            display: 'Say that you outsmarted the Goblin King',
            text: `"I managed to convince him that the crown was cursed and he willingly gave it to me."`,
            next: 'kingRehoboam.crownRequest.crownResponse.goblin',
          },
          {
            display: 'Say that you made a deal with the Goblin King',
            text: `"I offered him a trade for the crown, and he accepted."`,
            next: 'kingRehoboam.crownRequest.crownResponse.goblin',
          },
        ],
        goblin: {
          text: `"A cunning strategy. The Goblin King is not one to be underestimated. Now that you have my audience, is there anything else you wish to discuss?"`,
          options: [
            {
              display: "Ask for the king's blessing on your quest",
              text: `"Your Majesty, I seek your blessing on my quest to defeat The Dark One."`,
              next: 'kingRehoboam.crownRequest.crownResponse.goblin.questBlessing',
            },
            {
              display: "Ask for the king's blessing on your quest (informal)",
              text: `"Well, I was hoping you could give me your blessing on my quest to defeat The Dark One."`,
              next: 'kingRehoboam.crownRequest.crownResponse.goblin.questBlessing',
            },
          ],
          questBlessing: {
            text: `"A noble and perilous quest indeed. You have proven your valor in retrieving my crown. You have my full blessing. As it happens, we may have a plan that could aid you in this noble quest."`,
            options: [
              {
                display: 'Ask the king about the plan',
                text: `"Thank you, Your Majesty. What is this plan you speak of?"`,
                next: 'kingRehoboam.crownRequest.crownResponse.goblin.questBlessing.plan',
              },
              {
                display: 'Ask the king about the plan (informal)',
                text: `"Cool. What's the plan?"`,
                next: 'kingRehoboam.crownRequest.crownResponse.goblin.questBlessing.plan',
              },
            ],
            plan: {
              text: `"Our city wizard, Eldwin, has been working on a plan to defeat the Dark One. He believes he has found a way, but we needed a brave adventurer to carry it out. Your success in retrieving the crown proves you may be the one we've been waiting for."`,
              options: [
                {
                  display: 'Offer to meet with Eldwin about the plan',
                  text: `"I would be honored to meet with Eldwin and learn about this plan, Your Majesty."`,
                  next: 'kingRehoboam.crownRequest.crownResponse.goblin.questBlessing.plan.directToEldwin',
                },
                {
                  display: 'Ask the king for more information about the plan',
                  text: `"What is this plan that Eldwin has devised?"`,
                  next: 'kingRehoboam.crownRequest.crownResponse.goblin.questBlessing.plan.details',
                },
              ],
              directToEldwin: {
                text: `"Excellent! Eldwin resides in the wizard's tower in the eastern part of our fair city of Elarian. Seek him out, and he will provide you with the details of the plan. May the light guide your path, Thaddeus."`,
                next: 'kingRehoboam.audienceConclusion',
              },
              details: {
                text: `"I will leave the details to Eldwin. He resides in the wizard's tower in the eastern part of our fair city of Elarian. Seek him out, and he will provide you with the details of the plan. May the light guide your path, Thaddeus."`,
                next: 'kingRehoboam.audienceConclusion',
              },
            },
          },
        },
      },
    },
    audienceConclusion: {
      text: `"You have both served the kingdom well today. Cedric, I look forward to enjoying your fine wine. Thaddeus, may your future endeavors be as successful as this one. You are always welcome in my court."`,
      next: 'shopkeeperCedric.throneRoomDeparture',
    },
    talk: {
      text: `"Greetings, Thaddeus. Thank you for returning my crown. You have done this kingdom a great service."`,
    },
  },
};

const npc = {
  type: 'npc',
  location: 'elarianThroneRoom',
  x: 2,
  y: 5,
  name: 'King Rehoboam',
  tileImage: 'king',
  dialogueKey: 'kingRehoboam',
  dialogueFunction: () => 'kingRehoboam.talk',
};

export const kingRehoboam = { npc, dialogues };
