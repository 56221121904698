const dialogues = {
  goblin: {
    goblin1: {
      text: `"I am a goblin. I am not your friend."`,
    },
    goblin2: {
      text: `"Our king is the strongest of all goblins. You will never defeat him."`,
    },
    goblin3: {
      text: `"We are the goblins. We are the best."`,
    },
    goblin4: {
      text: `"I hope you are not here to challenge our king."`,
    },
    goblin5: {
      text: `"I am watching you."`,
    },
    goblin6: {
      text: `"You will never defeat our king."`,
    },
    goblin7: {
      text: `"You are not welcome here."`,
    },
    goblin8: {
      text: `"My name is Goblin. I am the best."`,
    },
  },
};

const baseGoblin = {
  type: 'npc',
  location: 'goblinCamp',
  x: 0,
  y: 1,
  name: 'Goblin 1',
  displayName: 'Goblin',
  tileImage: 'goblin',
  dialogueKey: 'goblin',
  dialogueFunction: () => 'goblin.goblin1',
};

const coordsList = [
  { x: 0, y: 1 },
  { x: 0, y: 2 },
  { x: 0, y: 3 },
  { x: 0, y: 4 },
  { x: 4, y: 1 },
  { x: 4, y: 2 },
  { x: 4, y: 3 },
  { x: 4, y: 4 },
];

const npcs = [];
for (let i = 0; i < 8; i++) {
  const goblin = { ...baseGoblin };
  goblin.name = `Goblin ${i + 1}`;
  goblin.dialogueFunction = () => `goblin.goblin${i + 1}`;
  goblin.x = coordsList[i].x;
  goblin.y = coordsList[i].y;
  npcs.push(goblin);
}

export const goblins = { npcs, dialogues };
