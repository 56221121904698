import { player } from './player.js';
import { dialogue } from './dialogue.js';
import { location } from './location/index.js';

const updateHealthBar = async (healthBarId, hp, maxHp) => {
  const hpDisplayId = healthBarId === 'enemyHealthLevel' ? 'enemyHpDisplay' : 'hpDisplay';
  if (typeof hp !== 'number') hp = player.hp;
  if (typeof maxHp !== 'number') maxHp = player.maxHp;
  document.getElementById(hpDisplayId).innerHTML = `${hp}/${maxHp}`;

  const healthBar = document.getElementById(healthBarId || 'healthLevel');
  let percentage = (hp / maxHp) * 100;
  if (percentage < 0) percentage = 0;
  healthBar.style.width = percentage + '%';

  if (percentage > 60) healthBar.style.backgroundColor = '#228b22';
  else if (percentage > 30) healthBar.style.backgroundColor = '#CCAA00';
  else healthBar.style.backgroundColor = '#AB0000';
};

const updateMagicBar = () => {
  document.getElementById('mpDisplay').innerHTML = `${player.mp}/${player.maxMp}`;

  const magicBar = document.getElementById('magicLevel');
  let percentage = (player.mp / player.maxMp) * 100;
  if (percentage < 0) percentage = 0;
  magicBar.style.width = percentage + '%';
};

const updateGold = amount => {
  if (amount) player.gold += amount;
  document.getElementById('goldDisplay').innerHTML = player.gold;
};

const loadSave = async slot => {
  slot = slot || 0;
  const saves = JSON.parse(localStorage.getItem('save'));
  player.saveId = slot;
  if (saves?.length && saves[slot]) {
    for (const key in player) {
      if (Object.prototype.hasOwnProperty.call(saves[slot], key)) player[key] = saves[slot][key];
    }
  }

  if (!player.hideGold) revealGold();
  if (!player.hideHealth) revealHealth();
  if (!player.hideMagic) revealMagic();

  if (!dialogue.checkDialogue('elarianGates.intro')) dialogue.displayDialogue('elarianGates.intro');
  else dialogue.clearScreen();
  // Display location second because the location might contain dialogue.
  location.displayLocation(player.currentLocation);

  if (player.hp > 0) location.showDisplay();
};

// The goal is to save the game every time the player object is updated.
const saveGame = () => {
  const save = JSON.parse(localStorage.getItem('save'));
  let saveFiles = save?.length ? save : [];
  if (saveFiles.length) saveFiles = saveFiles.map((save, index) => (index === player.saveId ? { ...player } : save));
  else saveFiles = [{ ...player }];
  localStorage.setItem('save', JSON.stringify(saveFiles));
};

// Gold, health, and magic are revealing during the tutorial.
const revealGold = () => {
  player.hideGold = false;
  document.getElementById('goldContainer').style.display = 'flex';
  document.getElementById('hud').style.justifyContent = 'space-between';
  updateGold();
};

const revealHealth = () => {
  player.hideHealth = false;
  document.getElementById('hpDisplay').innerHTML = `${player.hp}/${player.maxHp}`;
  document.getElementById('healthContainer').style.display = 'flex';
  updateHealthBar();
};

const revealMagic = () => {
  player.hideMagic = false;
  document.getElementById('mpDisplay').innerHTML = `${player.mp}/${player.maxMp}`;
  document.getElementById('magicContainer').style.display = 'flex';
  updateMagicBar();
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export const util = {
  updateHealthBar,
  updateMagicBar,
  updateGold,
  loadSave,
  saveGame,
  revealGold,
  revealHealth,
  revealMagic,
  delay,
};
