import { castleGuards } from './castleGuards.js';
import { kingsGuards } from './kingsGuards.js';
import { elarianGuards } from './elarianGuards.js';
import { courtyardGuards } from './courtyardGuards.js';

const dialogues = {
  ...castleGuards.dialogues,
  ...kingsGuards.dialogues,
  ...elarianGuards.dialogues,
  ...courtyardGuards.dialogues,
};

const npcList = [...castleGuards.npcs, ...kingsGuards.npcs, ...elarianGuards.npcs, ...courtyardGuards.npcs];

export const guards = { npcList, dialogues };
